<template>
  <div v-if="notificationsSupported === true">
    <v-btn v-if="notificationsActivated === true"
           color="#de8e55"
           icon
           @click="toggleNotifications">
      <v-icon large>mdi-bell</v-icon>
    </v-btn>
    <v-btn v-if="notificationsActivated === false"
           color="#de8e55"
           icon
           @click="toggleNotifications">
      <v-icon large>mdi-bell-off</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'AppNotificationButton',
  data: () => ({
    notificationsSupported: false,
    notificationAllowed: false,

    notificationsActivated: false,

    allowNotificationPrompt: false,

    pushManagerRegistration: null,
    serviceWorkerRegistration: null,

  }),
  created: function () {
    if ('serviceWorker' in navigator) {
      navigator
          .serviceWorker
          .register('/service-worker.js')
          .then((registration) => {

            if (!('PushManager' in window)) {
              console.log('Sorry, Push notification is ' + 'not supported on this browser.');
              this.notificationsSupported = false;
              return;
            }

            if (!registration) {
              console.log('service worker not registered');
              this.notificationsSupported = false;
              return;
            }
            if (!registration.pushManager) {
              console.log('This browser does not support push notification.');
              this.notificationsSupported = false;
              return;
            }

            this.notificationsSupported = true;

            if (Notification.permission !== 'granted') {
              this.notificationAllowed = false;
              return;
            }

            this.notificationAllowed = true;

            return registration
                .pushManager
                .getSubscription();
          })
          .then((subscription) => {
            if (!subscription) {
              this.notificationsActivated = false
              // this.subscribeToPushNotification();
            } else {
              this.notificationsActivated = true;
            }

          })
          .catch((error) => {
            console.log(error);
          });
    }
  },
  mounted() {
  },
  methods: {
    showNotification: function () {
      const options = {
        body: "Esto es una prueba",
        icon: '/icons/icon-192x192.png',
      };
      new Notification("Prueba", options);
    },
    toggleNotifications: function () {

      if (this.notificationsSupported === false)
        return;

      if (this.notificationsActivated === false) {
        if (this.notificationAllowed === false) {
          Notification.requestPermission().then((result) => {
            if (result === 'granted') {
              this.subscribeToPushNotification();
            } else {
              console.log('denied notifications')
            }
          });
        } else {
          this.subscribeToPushNotification();
        }
      } else {
        this.unsubscribeFromPushNotification();
      }
    },

    sendSubscriptionIDToServer: function (subscription) {
      const subscriptionId = subscription.endpoint.split('gcm/send/')[1];
      console.log("Subscription ID", subscriptionId);
      fetch('http://localhost:1337/subscribers', {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          subscriptionid: subscriptionId
        })
      });
    },
    removeSubscriptionIDFromServer: function (subscription) {
      const subscriptionId = subscription.endpoint.split('gcm/send/')[1];
      console.log("Subscription ID", subscriptionId);
      fetch('http://localhost:8080/subscribers/' + subscriptionId, {
        method: 'delete',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      });
    },

    /**
     *
     */
    subscribeToPushNotification: function () {
      navigator
          .serviceWorker
          .ready
          .then(function (registration) {
            return registration
                .pushManager
                .subscribe({
                  userVisibleOnly: true
                });
          })
          .then(function (subscription) {

            console.log('SIIIII')

            this.notificationsActivated = true;
            this.sendSubscriptionIDToServer(subscription)

          })
          .catch(function (error) {

            console.log('NOOOO')

            this.notificationsActivated = false;
            if (Notification.permission === 'denied') {
              console.log('Permission for Notifications was denied');
            } else {
              console.log('Unable to subscribe to push.', error);
            }

          })
    },
    unsubscribeFromPushNotification: function () {
      navigator.serviceWorker.ready
          .then(function (registration) {
            registration
                .pushManager
                .getSubscription()
                .then(function (subscription) {
                  if (!subscription) {
                    alert('Unable to unsubscribe from push notification.');
                    return;
                  }
                  subscription
                      .unsubscribe()
                      .then(function () {
                        console.log('Push notification unsubscribed.');
                        console.log(subscription);
                        this.removeSubscriptionIDFromServer(subscription);
                      })
                      .catch(function (error) {
                        console.error(error);
                      });
                })
                .catch(function (error) {
                  console.error('Failed to unsubscribe push notification.');
                });
          })
    }
  },
  props: ['business']
}
</script>

<style scoped>
.v-alert {
  position: fixed;
  z-index: 999;
  bottom: 0;
  left: 10px;
}
</style>
